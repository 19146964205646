import {
  FirestoreBooking,
  FirestoreBookingAppointmentSegment,
  FirestoreDog,
  FirestorePawrent,
  FirestorePawrentInternalFields,
  FirestoreTeamMember,
  FirestoreUser,
  FirestoreUserInternalSettings,
} from "../types/firebaseTypes"
import { DocumentSnapshot, Timestamp } from "firebase/firestore"
import { QueryDocumentSnapshot } from "firebase-admin/lib/firestore"
import { DocumentSnapshot as AdminDocumentSnapshot } from "firebase-admin/firestore"
import { AnyMap } from "../types/types"

type BookingServiceTranslation = {
  name: string
  abbreviation: string
  color: string
}

const BOOKING_SERVICES: { [key: string]: BookingServiceTranslation } = {
  TMECJ2YZ5D3BYNAYW4DVCIF5: {
    name: "Puppy's First Groom",
    abbreviation: "PUP",
    color: "pink.100",
  },
  NGPMIS7RQ57PNEYHWI2ASJ4M: {
    name: "Full Groom 20kg",
    abbreviation: "FG20",
    color: "orange.200",
  },
  WRJ3YHYJQ5TLYIBK7HQI7ZSP: {
    name: "Full Groom 8kg",
    abbreviation: "FG8",
    color: "orange.100",
  },
  V4QE7RBSDYVDM4TB6IV6KUWL: {
    name: "Double Coat Full Groom 8kg",
    abbreviation: "D-FG8",
    color: "yellow.100",
  },
  QFGHTBM5MRQ66U5YY2QXDLRF: {
    name: "Double Coat Full Groom 20kg",
    abbreviation: "D-FG20",
    color: "yellow.200",
  },
  TEGCEGA44OF7OOM4PTBJBWRQ: {
    name: "Matt Removal",
    abbreviation: "MATT",
    color: "brown.100",
  },
  HMRPBYKYXUGV4RJ7TAYVXIRW: {
    name: "Bath & Tidy",
    abbreviation: "BT",
    color: "blue.100",
  },
  "2NJXMSBFNDOPMQWBJ2CVKD62": {
    name: "Double Coat Bath & Tidy",
    abbreviation: "D-BT",
    color: "blue.200",
  },
  MX6CJ6QIA24LISAGCUSJBB3R: {
    name: "Luxury Shampoo Treatment",
    abbreviation: "LST",
    color: "yellow.100",
  },
  UUB3FNG26LM4THRTMQORUJLD: {
    name: "ManiPedi",
    abbreviation: "MP",
    color: "gray.100",
  },
  NIZQGRI242NA5I6VHRHWMDMN: {
    name: "Head Style",
    abbreviation: "HS",
    color: "gray.100",
  },
}

export const docToFirestoreUser = (
  doc: DocumentSnapshot | QueryDocumentSnapshot | AdminDocumentSnapshot
) => {
  const data = doc.data()
  return {
    id: doc.id,
    email: data?.email || "",
    role: data?.role || "",
    settings: {
      calendar: {
        showInactiveBookings: data?.settings?.calendar?.showInactiveBookings || false,
      },
    },
    createdAt: timestampToDate(data?.createdAt),
    updatedAt: timestampToDate(data?.updatedAt),
  } as FirestoreUser
}

export const docToFirestoreUserInternalSettings = (
  doc: DocumentSnapshot | QueryDocumentSnapshot | AdminDocumentSnapshot
) => {
  const data = doc.data()
  return {
    id: doc.id,
    calendarShowInactiveBookings: data?.calendarShowInactiveBookings || false,
  } as FirestoreUserInternalSettings
}

export const docToFirestorePawrent = (
  doc: DocumentSnapshot | QueryDocumentSnapshot | AdminDocumentSnapshot
) => {
  const data = doc.data()
  return {
    id: doc.id,
    email: data?.email || "",
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    phone: data?.phone || "",
    address: data?.address || "",
    emergencyName: data?.emergencyName || "",
    emergencyPhone: data?.emergencyPhone || "",
    createdAt: timestampToDate(data?.createdAt),
    updatedAt: timestampToDate(data?.updatedAt),
  } as FirestorePawrent
}

export const docToFirestorePawrentInternalFields = (
  doc: DocumentSnapshot | QueryDocumentSnapshot | AdminDocumentSnapshot
) => {
  const data = doc.data()
  return {
    id: doc.id,
    internalNotes: data?.internalNotes || "",
    termsPhysicalSigned: data?.termsPhysicalSigned || false,
    termsDigitalSigned: data?.termsDigitalSigned || false,
    createdAt: timestampToDate(data?.createdAt),
    updatedAt: timestampToDate(data?.updatedAt),
  } as FirestorePawrentInternalFields
}

export const docToFirestoreDog = (
  doc: QueryDocumentSnapshot | DocumentSnapshot | AdminDocumentSnapshot
) => {
  const data = doc.data()
  return {
    id: doc.id,
    name: data?.name || "",
    breed: data?.breed || "",
    color: data?.color || "",
    gender: data?.gender || "unknown",
    desexed: data?.desexed || "unknown",
    age: data?.age || -1,
    weight: data?.weight || -1,
    medicalNotes: data?.medicalNotes || "",
    dietaryNotes: data?.dietaryNotes || "",
    behavioralNotes: data?.behavioralNotes || "",
    groomingNotes: data?.groomingNotes || "",
    createdAt: timestampToDate(data?.createdAt),
    updatedAt: timestampToDate(data?.updatedAt),
  } as FirestoreDog
}

export const docToFirestoreBooking = (
  doc: DocumentSnapshot | QueryDocumentSnapshot | AdminDocumentSnapshot
) => {
  const data = doc.data()
  return {
    id: doc.id,
    customerId: data?.customerId || "",
    locationId: data?.locationId || "",
    status: data?.status || "DECLINED",
    appointmentSegments:
      data?.appointmentSegments?.map((segment: FirestoreBookingAppointmentSegment) => ({
        serviceVariationId: segment?.serviceVariationId || "",
        teamMemberId: segment?.teamMemberId || "",
        anyTeamMember: segment?.anyTeamMember || false,
        durationMinutes: segment?.durationMinutes || 0,
      })) || [],
    customerNote: data?.customerNote || "",
    sellerNote: data?.sellerNote || "",
    startAt: timestampToDate(data?.startAt),
    createdAt: timestampToDate(data?.createdAt),
    updatedAt: timestampToDate(data?.updatedAt),
  } as FirestoreBooking
}

export const docToFirestoreTeamMember = (
  doc: DocumentSnapshot | QueryDocumentSnapshot | AdminDocumentSnapshot
) => {
  const data = doc.data()
  return {
    id: doc.id,
    email: data?.email || "",
    phone: data?.phone || "",
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    status: data?.status || "INACTIVE",
    createdAt: timestampToDate(data?.createdAt),
    updatedAt: timestampToDate(data?.updatedAt),
  } as FirestoreTeamMember
}

export const timestampToDate = (timestamp?: Timestamp) =>
  timestamp ? new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6) : null

export const formatUserName = (firstName?: string, lastName?: string) =>
  [firstName, lastName]?.filter(name => !!name)?.join(" ") || "No name"

export const formatDogName = (name?: string) => name || "No name"

export const formatAge = (age: number) =>
  age > -1 ? `${age} year${age !== 1 ? "s" : ""} old` : "Unknown age"

export const formatBookingTime = (booking: FirestoreBooking) =>
  `${booking?.startAt?.getHours()?.toString()?.padStart(2, "0")}:${booking?.startAt?.getMinutes()?.toString()?.padStart(2, "0")}`

export const getBookingCancelled = (booking: FirestoreBooking) =>
  !["PENDING", "ACCEPTED"].includes(booking.status)

export const getBookingServiceTranslation = (serviceId: string) =>
  BOOKING_SERVICES?.[serviceId] || null

export const getOrderedBookingServices = (booking: FirestoreBooking) => {
  return booking.appointmentSegments.sort((a, b) => {
    if (a.durationMinutes > b.durationMinutes) return -1
    if (a.durationMinutes < b.durationMinutes) return 1
    return 1
  })
}

export const getReservedDogReadableId = (id: string) => {
  const dogIndex = id?.match(/^dog(\d?)$/)?.[1]
  if (!dogIndex) {
    return null
  }

  return `Dog ${dogIndex}`
}

/**
 * Remove all trailing spaces from values in an object
 */
export const cleanObject = (obj: AnyMap) =>
  Object.keys(obj)?.reduce((newChanges, key: string) => {
    if (typeof obj[key] === "string") {
      newChanges[key] = obj[key].trim()
    } else {
      newChanges[key] = obj[key]
    }
    return newChanges
  }, {} as AnyMap)
